























































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { LiveSessionProductSalesDialogProperties } from "./LiveSessionProductSalesDialogView.vue";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import StringUtil from "@/utilities/StringUtil";
import DateUtil from "@/utilities/DateUtil";

@Component({
    components: {},
    data: () => ({
        name: "sales-history",
        recordCounts: [10, 20, 30, 40, 50],
        pageNo: 1,
        pageCount: 1,
        recordCount: 20,
        details: [],
        totalQuantity: 0,
        totalAmount: 0,
        saving: false,
        loading: false,
        filter: {
            productCode: "",
            productName: "",
            customer: "",
            comment: "",
        },
    }),
})
export default class LiveSessionProductHistoriesView extends Vue {
    @Prop() private liveSessionProductSalesDialog: LiveSessionProductSalesDialogProperties;
    @Prop() private properties: LiveSessionInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private liveSessionDetailService = new LiveSessionDetailService();
    private handle: number = 0;
    private timerEnabled = true;
    private queueHandle: number = 0;

    public get headers() {
        return [
			{
				text: this.$t("text.created-date"),
				value: "createdDate",
				visible: true
			},
            {
                text: this.$t("text.product-name"),
                value: "liveSessionProduct.name",
				width: "13%",
                visible: this.fullscreenSales,
            },
            {
                text: this.$t("text.customer"),
                value: "customer",
                width: this.fullscreenSales ? "18%" : "30%",
                visible: true,
            },
            {
                text: this.$t("text.comment"),
                value: "comment",
                width: "22%",
                visible: this.fullscreenSales,
            },
            {
                text: this.$t("text.quantity"),
                value: "quantity",
                align: "right",
                width: "85px",
                visible: true,
            },
            {
                text: this.$t("text.price"),
                value: "price",
                align: "right",
                width: "85px",
                visible: true,
            },
            {
                text: this.$t("text.weight-g"),
                value: "weight",
                align: "right",
                width: "90px",
                visible: this.fullscreenSales,
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount",
                align: "right",
                width: "110px",
                visible: true,
            },
            {
                text: this.$t("text.total-weight-g"),
                value: "totalWeight",
                align: "right",
                width: "120px",
                visible: this.fullscreenSales,
            },
            {
                value: "action",
                align: "center",
                width: "120px",
                visible: true,
            },
        ].filter((x) => x.visible);
    }

    public get liveSession() {
        return this.properties.liveSession;
    }

    public get fullscreenSales() {
        return this.properties.fullscreenSales;
    }

    public get isStarted() {
        return this.liveSession.status === "Started";
    }

    public get recordCountProps() {
        return {
            top: true,
        };
    }

    public navigatorUrl(item: any) {
        const facebookAccountId = item.facebookAccountId;
        return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public created() {
        this.properties.events.subscribe("remove-detail", this.remove);
        this.properties.events.subscribe("filter-detail", this.filterDetail);
        this.properties.events.subscribe("load-product-sales", this.load);
    }

    public mounted() {
        this.load(true);
    }

    public beforeDestroy() {
        this.timerEnabled = false;
        if (this.handle !== 0) clearTimeout(this.handle);
    }

    public destroyed() {
        this.properties.events.remove("remove-detail", this.remove);
        this.properties.events.remove("filter-detail", this.filterDetail);
        this.properties.events.remove("load-product-sales", this.load);
    }

    public filterDetail(v: string) {
        if (v.startsWith("psid:")) {
            this.$data.filter.customer = v;
        }
        if (!this.isStarted) {
            this.load(false);
        }
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load(false);
        }, delay);
    }

    @Watch("properties.liveSession.status")
    public async load(reschedule: boolean = true) {
        const pageNo = this.$data.pageNo;
        const rowCount = this.$data.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.loading = true;

        try {
			const filter = this.$data.filter;
			const liveSessionId = this.liveSession?.id ?? 0;
            if (liveSessionId === 0) return;

			const r = await this.liveSessionDetailService.get({
				rowStart: rowStart,
				rowCount: rowCount,
				countRecord: true,
				liveSessionId: liveSessionId,
				liveSessionProductCode: StringUtil.enclose(filter.productCode, "%", "%"),
				liveSessionProductName: StringUtil.enclose(filter.productName, "%", "%"),
				searchCustomer: filter.customer,
				searchComment: filter.comment,
				loadFacebookComment: true,
				loadFacebookAccount: true,
				loadLiveSessionProduct: true,
				calculateTotal: true,
			});

			const details: any[] = r.data.liveSessionDetails;

			const labelPsid = this.$t("text.psid");
			const labelRemarks = this.$t("text.remarks");
			details.forEach(d => {
				const c = d.facebookComment;
				const f = d.facebookAccount;
				const n = f.fullName;
				const u = f.uniqueNo ?? null;
				const p = f.pageScopedId;
				const g = c?.message ?? "";
				const r = d.remarks ?? "";

				const t0 = u !== null ? `${n} [${u}]` : n;
				const t1 = p !== "" ? `${labelPsid}: ${p}` : "";
				const t2 = r !== "" ? `${labelRemarks}: ${r}` : "";

				d.customer = `${t0}\n${t1}`.trim();
				d.comment = `${g}\n${t2}`.trim();
			});
			
			this.$data.details = details.sort((lhs, rhs) => {
				return DateUtil.compare(rhs.createdDate, lhs.createdDate);
			});
			this.$data.totalQuantity = (r.data.totalQuantity ?? 0).toFixed(0);
			this.$data.totalAmount = (r.data.totalAmount ?? 0).toFixed(2);

			var count = Math.ceil(r.data.count / rowCount);
			if (count < 1) count = 1;
			await Vue.nextTick();
			this.$data.pageCount = count;
			if (pageNo > count) {
				this.$data.pageNo = count;
			}
        } catch (e) {
            //ignored
            console.log(e);
        } finally {
            this.$data.loading = false;

            if (this.timerEnabled && reschedule && this.isStarted) {
                if (this.handle !== 0) clearTimeout(this.handle);
                this.handle = setTimeout(this.load, 2000);
            }
        }
    }

	public create() {
		if (this.$data.saving) {
			return;
		}

		const product = this.properties.product;
		const productId = product?.id ?? 0;
		if (productId === 0) {
			var m = this.$t("message.live-session-product-not-introducing");
			AlertDialogProperties.info(this.alertDialog, null, m);
			this.alertDialog.visible = true;
		} else {
			this.liveSessionProductSalesDialog.liveSessionProduct = product;
			this.liveSessionProductSalesDialog.liveSessionDetailId = null;
			this.liveSessionProductSalesDialog.isNew = true;
			this.liveSessionProductSalesDialog.visible = true;
		}
	}

    public modify(record: any) {
        if (this.$data.saving) {
            return;
        }

		const product = this.properties.product;
		this.liveSessionProductSalesDialog.liveSessionProduct = product;
        this.liveSessionProductSalesDialog.liveSessionDetailId = record.id;
		this.liveSessionProductSalesDialog.isNew = false;
        this.liveSessionProductSalesDialog.visible = true;
    }

    public removeDialog(record: any) {
        var m = "product: " + record.liveSessionProduct.name;
        m += ", customer: " + record.facebookAccount.fullName;

        AlertDialogProperties.delete(this.alertDialog, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "remove-detail", record };
        this.alertDialog.visible = true;
    }

    public async remove(record: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.liveSessionDetailService.delete(record);
            if (this.liveSession.status === "Ended") {
				await this.properties.events.fire("load-product-sales", false);
				await this.properties.events.fire("load-last-accepted", false);
				await this.properties.events.fire("load-comments", true, false);
                await this.load(false);
            }
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public switchFullscreenSales() {
        Vue.set(this.properties, "fullscreenSales", !this.fullscreenSales);
    }
}
