var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-2 pb-3"},[_c('v-data-table',{staticClass:"mx-2 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.details,"items-per-page":_vm.recordCount,"page":_vm.pageNo,"loading":!_vm.isStarted && _vm.loading,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.create")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":""},on:{"click":_vm.switchFullscreenSales}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.fullscreenSales ? "mdi-fullscreen-exit" : "mdi-fullscreen")+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.fullscreen')))])])]},proxy:true},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td'),(_vm.fullscreenSales)?_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.productName),callback:function ($$v) {_vm.$set(_vm.filter, "productName", $$v)},expression:"filter.productName"}})],1):_vm._e(),_c('td',[_c('v-layout',[_c('v-text-field',{staticClass:"flex mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.customer),callback:function ($$v) {_vm.$set(_vm.filter, "customer", $$v)},expression:"filter.customer"}}),_c('v-btn',{staticClass:"flex mt-1",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.filter.customer = '';_vm.loadQueue();}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1),(_vm.fullscreenSales)?_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.comment),callback:function ($$v) {_vm.$set(_vm.filter, "comment", $$v)},expression:"filter.comment"}})],1):_vm._e(),_c('td',{attrs:{"colspan":_vm.fullscreenSales ? 6 : 4}})])]}},{key:"item.createdDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a',{staticClass:"pre-wrap inherit-color no-underline",attrs:{"href":_vm.navigatorUrl(item),"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.comment",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"pre-wrap"},[_vm._v(_vm._s(value))])]}},{key:"item.price",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.weight",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.totalWeight",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.totalAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.modify(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.modify")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.removeDialog(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.delete")))])])]}}])}),_c('v-divider',{staticClass:"mt-2 mb-3"}),_c('div',{staticClass:"text-right body-2 pr-3 mb-3"},[_c('span',{staticClass:"pr-6"},[_vm._v(" "+_vm._s(_vm.$t("text.total-quantity") + ": " + _vm.totalQuantity)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("text.total-amount") + ": " + _vm.totalAmount)+" ")])]),_c('v-layout',{staticClass:"px-3",attrs:{"d-flex":"","id":"histories-page-control"}},[_c('v-flex',{attrs:{"shrink":"","id":"histories-record-count"}},[_c('v-select',{staticClass:"mt-4 text-center",attrs:{"label":_vm.$t('text.history-count'),"items":_vm.recordCounts,"menu-props":_vm.recordCountProps,"hide-details":"","dense":""},on:{"input":function($event){_vm.pageNo = 1;_vm.load(false);}},model:{value:(_vm.recordCount),callback:function ($$v) {_vm.recordCount=$$v},expression:"recordCount"}})],1),_c('v-flex',[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"12"},on:{"input":function($event){return _vm.load(false)}},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }